import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src020753457/src/braceta-com-website/gatsby-theme-terminal/src/layouts/PageLayout.js";
import { format } from 'date-fns';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SourceList = makeShortcode("SourceList");
const Flex = makeShortcode("Flex");
const Box = makeShortcode("Box");
const Link = makeShortcode("Link");
const Card = makeShortcode("Card");
const Image = makeShortcode("Image");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`posts`}</h1>
    <SourceList filter="posts" mdxType="SourceList">
  {posts => <Flex sx={{
        flexWrap: "wrap",
        ml: theme => `-${theme.space[2]}px`,
        mr: theme => `-${theme.space[2]}px`
      }} mdxType="Flex">
      {posts.map((edge, index) => {
          const {
            frontmatter: {
              title,
              featuredImage,
              tags,
              date
            },
            excerpt,
            fields: {
              slug
            }
          } = edge.node;
          return <Box key={index} sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            mb: 3,
            maxWidth: ["100%", "100%", "100%", "100%"],
            width: ["100%", "100%", "100%", "100%"]
          }} mdxType="Box">
             <Link href={slug} sx={{
              textDecoration: "none",
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              m: theme => `0px ${theme.space[2]}px`,
              minHeight: '1px'
            }} mdxType="Link">
    
               <Card sx={{
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'column',
                minHeight: '1px'
              }} mdxType="Card">
                {featuredImage && featuredImage.childImageSharp && <Box sx={{
                  minHeight: '1px'
                }} mdxType="Box">
                    <Image src={featuredImage.childImageSharp.fluid.src} alt={featuredImage.childImageSharp.fluid.originalName} mdxType="Image" />
                  </Box>}
                  <Box sx={{
                  display: 'flex',
                  flex: '1 1 auto',
                  flexDirection: 'column',
                  p: 3
                }} mdxType="Box">
                  <Heading variant="styles.h4" sx={{
                    color: "text"
                  }} mdxType="Heading">
                    {title}
                  </Heading>
                   <Text sx={{
                    mb: 1,
                    color: 'muted'
                  }} mdxType="Text">{format(new Date(date), 'd-MMM-u')}</Text>
                   <Text sx={{
                    mb: 1,
                    color: 'text'
                  }} mdxType="Text">{excerpt}</Text>
                  </Box>
                  <Box sx={{
                  p: 3
                }} mdxType="Box">
                  <Text mdxType="Text">View Post</Text>
                  </Box>
              </Card>
   
            </Link> 
          </Box>;
        })}
    </Flex>}
    </SourceList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      